﻿import { configureStore } from '@reduxjs/toolkit';
import reducers, { type RootState } from '../reducers'; // Assuming you are using a combined reducer from traditional redux setup

let serverState = {};

try {
  // Retrieve and clean up the initial state from the server (if present)
  serverState = window.__INITIAL__STATE__ || {};
  delete window.__INITIAL__STATE__;
} catch (_e) {
  serverState = {};
}

export default (initialState: RootState | null = null) => {
  return configureStore({
    reducer: reducers,
    preloadedState: initialState || serverState,
    // Redux DevTools is enabled automatically in development mode
    devTools: process.env.NODE_ENV === 'development',
  });
};
