import { useEffect } from 'react';
import { getAccessToken } from './actions/auth';
import { init } from './actions/event';
import { facets } from './actions/search';
import { track } from './actions/tracking';
import { LiveShopping } from './shared/tracking';
import { auth, phonenumbers, pubsub } from './utils';

interface BabumserEvent {
  detail: {
    data: {
      interactionType?: string;
      showId?: string;
      timeline?: {
        isLive: boolean;
      };
    };
  };
}

type BabumserEventType = Event & BabumserEvent;

interface ClientInitializerProps {
  store: any;
}

const ClientInitializer = ({ store }: ClientInitializerProps) => {
  useEffect(() => {
    if (!process.env.SSR) {
      const { dispatch, getState } = store;

      auth.createRefreshTokenHandler(dispatch, getState);
      getAccessToken()(dispatch);

      facets()(dispatch);

      pubsub.attach(window, dispatch);
      phonenumbers.initialize();

      init()(dispatch, getState);
      window.addEventListener('bambuser-liveshop-tracking-point', (event) => {
        const { detail } = event as BabumserEventType;
        if (detail.data.interactionType) {
          track(LiveShopping.LIVE_SHOPPING_INTERACTION, {
            information: {
              showId: detail?.data?.showId,
              interactionType: detail?.data?.interactionType,
              isLive: detail?.data?.timeline?.isLive,
            },
          })(dispatch, getState);
        }
      });
    }
  }, []);

  return null;
};

export default ClientInitializer;
