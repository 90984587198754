import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { facets } from '../../actions/item';
import QueryFilter from '../../helpers/query-filter';
import { getManufactureFacetList } from '../../selectors/search';
import { H5 } from '../../styled-components/components/Heading';
import LinkCloud from '../../styled-components/components/LinkCloud';
import { getLinkCloudForAdvertisement, getSearchDocument } from '../../utils/reselectors';

const AdvertisementFooterLinkCloud = () => {
  const { t } = useTranslation(['item']);
  const match = useMatch('/objekt/:id');

  const dispatch = useDispatch();
  const item = useSelector(getSearchDocument);
  const links = useSelector(getLinkCloudForAdvertisement);
  const hasManufactureFacetList = !!useSelector(getManufactureFacetList);

  const actions = bindActionCreators({ facets }, dispatch);

  useEffect(() => {
    if (!!item && item.id === match?.params?.id) {
      const qf = QueryFilter.byProperty('manufacturer', item.manufacturer);
      actions.facets(qf);
    }
  }, [item?.id]);

  if (!item || !hasManufactureFacetList) return null;

  return (
    <div className="border-top">
      <div className="container m-y-double">
        <H5 as="h2">
          {t('moreModelsFromManufacturer', { ns: 'item', manufacturer: item.manufacturer })}
        </H5>
        <LinkCloud
          items={links
            .filter((link) => link.name !== item.modelSeries)
            .sort((a, b) => b.count - a.count)
            .slice(0, 20)
            .map((link) => ({
              text: link.name,
              textTail: `(${link.count})`,
              title: t('showNewAndUsedVehiclesCategory', { ns: 'item', category: link.name }),
              to: link.target,
            }))}
        />
      </div>
    </div>
  );
};

export default AdvertisementFooterLinkCloud;
