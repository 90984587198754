﻿/* Other imports */
import '../stylesheets/default.scss';

import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import QueryClientSetup from './QueryClientSetup';
import configureStore from './shared/store';
import Fonts from './styled-components/layout/fonts';
import GlobalStyle from './styled-components/layout/global-style';
import theme from './styled-components/layout/theme';

import { Routes } from './routes';

import ClientInitializer from './client-initializer';
import getI18n, { getMarket } from './i18n';
import SuspenseLoaderProvider from './providers/SuspenseLoaderProvider';

const queryClient = QueryClientSetup(undefined, undefined, location.search, getMarket().marketCode);
const store = configureStore();

window.addEventListener(
  'load',
  () => {
    if (window && window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(() => {
        window.googletag?.pubads().enableSingleRequest();
        window.googletag?.pubads().collapseEmptyDivs();
        window.googletag?.pubads().setCentering(true);
        window.googletag?.enableServices();
      });
    }

    const node = document.querySelector('#wayke-web-ui');
    if (node) {
      const i18n = getI18n(window.__WAYKE_MARKET__);
      const market = getMarket();

      hydrateRoot(
        node,
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <SuspenseLoaderProvider>
                  <ClientInitializer store={store} />
                  <Fonts market={market.marketCode} />
                  <GlobalStyle />
                  <Routes />
                </SuspenseLoaderProvider>
              </Provider>
            </QueryClientProvider>
          </I18nextProvider>
        </ThemeProvider>,
      );
    }
  },
  true,
);
